import Vue from "vue";
import VueRouter from "vue-router";
// 引入store
import store from "../store";
// 引入工具:storage.js
import storage from "../utils/storage";

// 用户
const Home = () => import("@/views/user/Home");
const ProductPage = () => import("@/views/user/ProductPage");
const ProductPageOther = () => import("@/views/user/ProductPageOther");
const ProductDetail = () => import("@/views/user/ProductDetail");
const News = () => import("@/views/user/News");
const NewsList = () => import("@/views/user/News/components/NewsList");
const NewsDetail = () => import("@/views/user/News/components/NewsDetail");
const Cases = () => import("@/views/user/Cases");
const CasesList = () => import("@/views/user/Cases/components/CasesList");
const CasesDetail = () => import("@/views/user/Cases/components/CasesDetail");
const EvaluateDetail = () =>
  import("@/views/user/Cases/components/EvaluateDetail");
const About = () => import("@/views/user/About");
const Qualification = () =>
  import("@/views/user/About/components/Qualification");
const Msg = () => import("@/views/user/Msg");

// 管理员
const Login = () => import("@/views/admin/Login");
const Background = () => import("@/views/admin/Background");
const Data = () => import("@/views/admin/Data");
const ProductAdmin = () => import("@/views/admin/ProductAdmin");
const QualificationAdmin = () => import("@/views/admin/QualificationAdmin");
const CasesAdmin = () => import("@/views/admin/CasesAdmin");
const NewsAdmin = () => import("@/views/admin/NewsAdmin");
const MsgAdmin = () => import("@/views/admin/MsgAdmin");
const ResourceAdmin = () => import("@/views/admin/ResourceAdmin");
const OtherAdmin = () => import("@/views/admin/OtherAdmin");
const SystemAdmin = () => import("@/views/admin/SystemAdmin");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "单级离心泵-不锈钢自吸泵-磁力驱动泵-化工液下泵-建安泵业",
      requireAuth: false,
    },
  },
  {
    path: "/productpage",
    name: "productpage",
    component: ProductPage,
    meta: {
      title: "产品列表",
      requireAuth: false,
    },
  },
  {
    path: "/productpageother",
    name: "productpageother",
    component: ProductPageOther,
    meta: {
      title: "产品列表",
      requireAuth: false,
    },
  },
  {
    path: "/productdetail",
    name: "productdetail",
    component: ProductDetail,
    meta: {
      title: "产品详情",
      requireAuth: false,
    },
  },
  {
    path: "/news",
    name: "news",
    component: News,
    meta: {
      title: "新闻列表",
      requireAuth: false,
    },
    children: [
      {
        path: "/newslist",
        name: "newslist",
        component: NewsList,
        meta: {
          title: "新闻列表",
          requireAuth: false,
        },
      },
      {
        path: "/newsdetail",
        name: "newsdetail",
        component: NewsDetail,
        meta: {
          title: "新闻详情",
          requireAuth: false,
        },
      },
    ],
  },
  {
    path: "/cases",
    name: "cases",
    component: Cases,
    meta: {
      title: "客户案例",
      requireAuth: false,
    },
    children: [
      {
        path: "/caseslist",
        name: "caseslist",
        component: CasesList,
        meta: {
          title: "客户案例",
          requireAuth: false,
        },
      },
      {
        path: "/casesdetail",
        name: "casesdetail",
        component: CasesDetail,
        meta: {
          title: "客户案例",
          requireAuth: false,
        },
      },
      {
        path: "/evaluatedetail",
        name: "evaluatedetail",
        component: EvaluateDetail,
        meta: {
          title: "客户评价",
          requireAuth: false,
        },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "走进建安",
      requireAuth: false,
    },
  },
  {
    path: "/qualification",
    name: "qualification",
    component: Qualification,
    meta: {
      title: "资质荣誉",
      requireAuth: false,
    },
  },
  {
    path: "/msg",
    name: "msg",
    component: Msg,
    meta: {
      title: "联系建安",
      requireAuth: false,
    },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "后台登陆",
      requireAuth: false,
    },
  },

  {
    path: "/background",
    name: "background",
    component: Background,
    meta: {
      title: "数据大屏",
    },
    children: [
      {
        path: "/data",
        name: "data",
        component: Data,
        meta: {
          title: "数据大屏",
          requireAuth: true,
        },
      },
      {
        path: "/productadmin",
        name: "productadmin",
        component: ProductAdmin,
        meta: {
          title: "产品中心",
          requireAuth: true,
        },
      },
      {
        path: "/qualificationadmin",
        name: "qualificationadmin",
        component: QualificationAdmin,
        meta: {
          title: "资质荣誉",
          requireAuth: true,
        },
      },
      {
        path: "/casesadmin",
        name: "casesadmin",
        component: CasesAdmin,
        meta: {
          title: "客户案例",
          requireAuth: true,
        },
      },
      {
        path: "/newsadmin",
        name: "newsadmin",
        component: NewsAdmin,
        meta: {
          title: "客户案例",
          requireAuth: true,
        },
      },
      {
        path: "/msgadmin",
        name: "msgadmin",
        component: MsgAdmin,
        meta: {
          title: "留言管理",
          requireAuth: true,
        },
      },
      {
        path: "/resourceadmin",
        name: "resourceadmin",
        component: ResourceAdmin,
        meta: {
          title: "资源管理",
          requireAuth: true,
        },
      },
      {
        path: "/otheradmin",
        name: "otheradmin",
        component: OtherAdmin,
        meta: {
          title: "其他管理",
          requireAuth: true,
        },
      },
      {
        path: "/systemadmin",
        name: "systemadmin",
        component: SystemAdmin,
        meta: {
          title: "系统管理",
          requireAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // 设置路由守卫，在进页面之前，判断有token，才进入页面，否则返回登录页面
  if (storage.get("token")) {
    store.commit("setToken", storage.get("token"));
  }
  // 判断要去的路由有没有requireAuth
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (store.state.token) {
      next(); //有token，进行request请求，后台还会验证token
    } else {
      router.push({
        path: "/login",
        // 将刚刚要去的路由path作为参数，方便直接跳转
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next(); //如果无需token，放行
  }
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.title = to.meta.title;
});
export default router;
