import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 使用bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 使用animatecss
import "animate.css";
import { vueAccordion } from "vue-accordion";

import MetaInfo from "vue-meta-info";
Vue.use(MetaInfo);

Vue.component("vue-accordion", vueAccordion);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
